<app-modal-v2>
  <div class="modal-xs rent-deposit">
    <app-modal-content>
      <div class="rent-deposit__body">
        @switch (true) {
          @case (isApproved) {
            <div
              class="mb70 title-xl rent-deposit__title d-flex justify-content-center"
            >
              {{ 'rent_deposit.status_modal.approved_header_l' | translate }}
            </div>
            <div class="d-flex justify-content-center">
              <svg-icon
                [src]="'/assets/images/icons/icon-coin-group.svg'"
                [applyClass]="true"
                [svgStyle]="{ 'height.px': 80, 'width.px': 80 }"
                class="green-svg-color path ellipse"
              ></svg-icon>
            </div>
            <h1 class="mt30 mb30 d-flex justify-content-center">
              {{ 'rent_deposit.status_modal.approved_title_l' | translate }}
            </h1>
            <p class="rent-deposit__message d-flex justify-content-center">
              @if (isYearly) {
                <span
                  [innerHTML]="
                    'rent_deposit.status_modal.approved_body_yearly_l'
                      | translate
                        : { amount: rentDepositInfo?.ruvData?.yearlyAmount }
                  "
                ></span>
              }
              @if (isMonthly) {
                <span
                  [innerHTML]="
                    'rent_deposit.status_modal.approved_body_monthly_l'
                      | translate
                        : { amount: rentDepositInfo?.ruvData?.monthlyAmount }
                  "
                ></span>
              }
            </p>
            <div class="mt25 mb15 d-flex justify-content-center">
              <span class="approved-deposit__label pr5">
                {{ 'rent_deposit.card.insurance_number_l' | translate }}
              </span>
              {{ rentDepositInfo?.ruvData?.areaOfOperation }} -
              {{ rentDepositInfo?.ruvData?.insuranceNumber }}
            </div>
            <div class="d-flex justify-content-center">
              <span class="approved-deposit__label pr5">{{
                'rent_deposit.card.delivery_l' | translate
              }}</span>
              {{
                (rentDepositInfo?.sendDocumentsToLandlord
                  ? 'rent_deposit.card.delivery_landlord_l'
                  : 'rent_deposit.card.delivery_tenant_l'
                ) | translate
              }}
            </div>
          }
          @case (isDeclined) {
            <div
              class="mb70 title-xl rent-deposit__title d-flex justify-content-center"
            >
              {{ 'rent_deposit.status_modal.declined_header_l' | translate }}
            </div>
            <div class="d-flex justify-content-center">
              <svg-icon
                [src]="'/assets/images/icons/icon-declined.svg'"
                [applyClass]="true"
                [svgStyle]="{ 'height.px': 80, 'width.px': 80 }"
                class="negative-svg-color rect line"
              ></svg-icon>
            </div>
            <h1 class="mt30 mb30 d-flex justify-content-center">
              {{ 'rent_deposit.status_modal.declined_title_l' | translate }}
            </h1>
            <p
              class="rent-deposit__message d-flex justify-content-center"
              [innerHTML]="
                'rent_deposit.status_modal.declined_body_l' | translate
              "
            ></p>
          }
          @case (inRevision) {
            <div
              class="mb70 title-xl rent-deposit__title d-flex justify-content-center"
            >
              {{ 'rent_deposit.status_modal.revision_header_l' | translate }}
            </div>
            <div class="d-flex justify-content-center">
              <svg-icon
                [src]="'/assets/images/icons/icon-clock.svg'"
                [applyClass]="true"
                [svgStyle]="{ 'height.px': 80, 'width.px': 80 }"
                class="yellow-svg-color circle polyline"
              ></svg-icon>
            </div>
            <h1 class="mt30 mb30 d-flex justify-content-center">
              {{ 'rent_deposit.status_modal.revision_title_l' | translate }}
            </h1>
            <p
              class="rent-deposit__message d-flex justify-content-center"
              [innerHTML]="
                'rent_deposit.status_modal.revision_body_l' | translate
              "
            ></p>
          }
          @case (failed) {
            <div
              class="mb70 title-xl rent-deposit__title d-flex justify-content-center"
            >
              {{ 'rent_deposit.status_modal.failed_header_l' | translate }}
            </div>
            <div class="d-flex justify-content-center">
              <svg-icon
                [src]="'/assets/images/icons/icon-no.svg'"
                [applyClass]="true"
                [svgStyle]="{ 'height.px': 80, 'width.px': 80 }"
                class="negative-svg-color path"
              ></svg-icon>
            </div>
            <h1 class="mt30 mb30 d-flex justify-content-center">
              {{ 'rent_deposit.status_modal.failed_title_l' | translate }}
            </h1>
            <p
              class="rent-deposit__message d-flex justify-content-center"
              [innerHTML]="
                'rent_deposit.status_modal.failed_body_l' | translate
              "
            ></p>
          }
        }
      </div>
    </app-modal-content>

    <app-modal-footer>
      <app-button (clickEvent)="close()" [type]="'primary'">
        {{ 'rent_deposit.done_a' | translate }}</app-button
      >
    </app-modal-footer>
  </div>
</app-modal-v2>
